@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Inter");

.App {
  text-align: center;
  align-content: center;
  left: auto;
  right: auto;
  max-width: 1800px;
  margin: auto;
  /* border: 1px solid black; */
  min-height: 100vh;
  font-family: "Inter";
}

.ant-menu-item .ant-menu-item-selected {
  background-color: red;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.ct_forms_secondary_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 24px;
  opacity: 0.75;
  border: 2px solid #272e35;
  box-sizing: border-box;
  border-radius: 16px;
  font-weight: bold;
}

.ct_forms_primary_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px;
  background: #ff6e30;
  border-radius: 16px;
  font-weight: bold;
}

.ct_forms_input_field {
  font-size: 24px;
  font-weight: 600;
  color: #272e35;
  word-break: break-word;
  border-bottom: 0.5px solid #272e35;
  margin-bottom: 10px;
}

.ct_forms_breadcrumb {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding-left: 10px;
  color: #748fb5;
}

.ct_forms_h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;

  color: #272e35;
}

@primary-color: #FF6E30;